import { Button, Form } from 'react-bootstrap';
import AppPage from './AppPage';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { registerPartner } from '../api/RegistrationApi';
import { errorHandler } from '../api/Api';
import { confirmAlert } from 'react-confirm-alert';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/auth';

const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

// TODO vytáhnout to někam do pryč
export interface Registration {
  'jmeno': string;
  'prijmeni': string;
  'telefon': string;
  'email': string;
  'heslo': string;
  'heslo2': string;
  'kod': string;
  'varianta': string;
}

export default function RegistracePage() {

  const SECURITY_CODE_LENGTH = 6;
  const [securityCode, setSecurityCode] = useState("");
  const [imgSrc, setImgSrc] = useState("");
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const navigate = useNavigate();

  const auth = useAuth();

  useEffect(() => {
    setIsAdmin(auth?.login != null && auth?.role === 'ADMIN');
  }, [auth?.login, auth?.role]);

  useEffect(() => generateSecurityCodeImage(), []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Registration>()

  const generateSecurityCodeImage = () => {
    const code = Array.from(Array(SECURITY_CODE_LENGTH), () =>
      Math.floor(Math.random() * 36).toString(36)
    ).join("");
    setImgSrc(`data:image/svg+xml,${encodeURIComponent(
      `<svg xmlns="http://www.w3.org/2000/svg" width="80" height="50"><foreignObject width="100%" height="100%"><div xmlns="http://www.w3.org/1999/xhtml" style="display:block;"><span style="margin:auto;font-size: 24px">${code}</span></div></foreignObject></svg>`
    )}`);
    setSecurityCode(code);
  };

  const handleRegister = async (data: Registration) => {
    await errorHandler(() => registerPartner(data));
    confirmAlert({
      title: 'Potvrzení registrace',
      message: 'Registrace byla úspěšně dokončena. Na Vámi zadanou e-mailovou adresu byl odeslán e-mail s dalšími informacemi. Po zavření dialogu budete automaticky přesměrování na hlavní stránku aplikace.',
      buttons: [
        {
          label: 'OK',
          onClick: () => navigate('/')
        }
      ]
    });
  };

  const validateCaptcha = (captcha: string) => {
    if (captcha !== securityCode) {
      return "Kontrolní kód nesouhlasí";
    }
  }

  const validatePasswordsMatch = (heslo: string, data: Registration) => {
    if (data.heslo !== data.heslo2) {
      return "Hesla nesouhlasí";
    }
  }

  const validatePasswordComplexity = (heslo: string, data: Registration) => {
    if (!heslo || heslo.length < 8 || heslo.length > 64 || heslo.search(/\d/) < 0 || heslo.search(/[a-z]/) < 0 || heslo.search(/[A-Z]/) < 0) {
      return "Heslo musí splňovat následující požadavky: délka 8-64 znaků, alespoň jedna číslice, alespoň jedno malé písmeno, alespoň jedno velké písmeno"
    }
  }

  const validateEmail = (email: string, data: Registration) => {
    if (!email || email.length === 0) {
      return "Pole je povinné";
    }
    if (!emailRegex.test(email)) {
      return "E-mail má neplatný formát";
    }
  }

  const validateVarianta = (varianta: string) => {
    console.log(varianta);
    if (!varianta || varianta.length === 0) {
      return "Je nutné zvolit variantu";
    }
  }

  return <AppPage>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h1>Registrace</h1>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Form onSubmit={handleSubmit(data => handleRegister(data))}>
          <Form.Control placeholder='Jméno' {...register("jmeno", { required: true })} />
          {errors.jmeno && <span className='error'>Jméno je povinné</span>}
          <Form.Control placeholder='Příjmení' {...register("prijmeni", { required: true })} style={{ marginTop: "10px" }} />
          {errors.prijmeni && <span className='error'>Příjmení je povinné</span>}
          <Form.Control placeholder='Telefon' {...register("telefon", { required: true })} style={{ marginTop: "10px" }} />
          {errors.telefon && <span className='error'>Telefon je povinný</span>}
          <Form.Control placeholder='E-mail' {...register("email", { required: true, validate: validateEmail })} style={{ marginTop: "10px" }} />
          {errors.email && <span className='error'>{errors.email.message || "E-mail je povinný"}</span>}
          <Form.Control type='password' placeholder='Heslo' {...register("heslo", { required: true, min: 8, max: 64, validate: validatePasswordComplexity })} style={{ marginTop: "10px" }} />
          {errors.heslo && <span className='error'>{errors.heslo.message || "Heslo je povinné"}</span>}
          <Form.Control type='password' placeholder='Heslo znovu' {...register("heslo2", { required: true, min: 8, max: 64, validate: validatePasswordsMatch })} style={{ marginTop: "10px" }} />
          {errors.heslo2 && <span className='error'>{errors.heslo2.message || "Heslo je povinné"}</span>}
          {!isAdmin && <Form.Select {...register("varianta", { required: !isAdmin, validate: validateVarianta })} style={{ marginTop: "10px" }}>
            <option value="">Vyberte variantu</option>
            <option value="PULROK">Půlroční</option>
            <option value="ROK">Roční</option>
          </Form.Select>}
          {errors.varianta && <span className='error'>{errors.varianta.message || "Je nutné zvolit variantu"}</span>}
          {!isAdmin && <Form.Control autoComplete='off' placeholder='Kód z obrázku níže' {...register("kod", { required: !isAdmin, min: SECURITY_CODE_LENGTH, max: SECURITY_CODE_LENGTH, validate: validateCaptcha })} style={{ marginTop: "10px" }} />}
          {!isAdmin && errors.kod && <span className='error'>{errors.kod.message || "Ověřovací kód je povinný"}</span>}
          {!isAdmin && <img alt="captcha" src={imgSrc} style={{ display: "block", marginLeft: "auto", marginRight: "auto", marginTop: "20px" }} />}
          {/* <Button onClick={() => generateSecurityCodeImage()} style={{ alignSelf: 'flex-end', marginBottom: '20px' }}>Refresh</Button> */}
        </Form>
        <Button onClick={handleSubmit(data => handleRegister(data))} style={{ marginTop: '20px' }}>Registrovat</Button>
      </div>
    </div>
  </AppPage>
}
